<template>
    <el-drawer v-model="isShow" size="60%" :show-close="false">
        <template #header>
            进件渠道 - {{ channel ? channel.name : '' }}
        </template>
        <div class="main" v-loading="loading" element-loading-text="进件中..." :element-loading-spinner="svg"
            element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="rgba(122, 122, 122, 0.8)">
            <div class="choose">
                <!-- <template v-if="channel && channel.code == 'lakala'"> -->
                <!-- <el-form ref="formRef" :model="incomingForm" label-width="150px">
                        <el-form-item label="机构号：" prop="institution_no">
                            <el-select v-model="incomingForm.institution_no">
                                <el-option v-for="mechanis in mechanism" :key="mechanis.name" :value="mechanis.value"
                                    :name="mechanis.name">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <template v-if="incomingForm.proto_no">
                            <el-form-item label="协议编号：" prop="proto_no">
                                <el-input v-model="incomingForm.proto_no" disabled readonly></el-input>
                            </el-form-item>
                        </template>
                        <template v-else>
                            <el-form-item label="协议：">
                                <el-button @click="applyProto" :disabled="isDisabledApplyAgreement"
                                    :loading="applyAgreementLoading">
                                    申请签署协议
                                </el-button>
                                <span style="color:red;margin-left:5px">申请签署协议前必须填写费率</span>
                            </el-form-item>
                        </template>
                    </el-form> -->
                <!-- </template> -->
                <template v-if="isFlowId && channel && channel.code == 'yinsheng'">
                    <el-form ref="formRef">
                        <el-form-item label="进件流水号：" prop="sys_flow_id">
                            <el-input v-model="incomingForm.sys_flow_id" disabled readonly></el-input>
                        </el-form-item>
                    </el-form>
                </template>
                <tmeplate v-if="channel && channel.code == 'post'">
                    <el-form ref="formRef">
                        <el-form-item label="微信特约商户号：" prop="wx_mer_no">
                            <el-input v-model="incomingForm.wx_mer_no" />
                        </el-form-item>
                    </el-form>
                </tmeplate>
            </div>
            <div class="methods">
                <payment_method ref="paymentRef" v-if="channel" :methods="channel.payment_methods" :isShowExtraCost="false"
                    :channelCode="channel.code">
                </payment_method>
            </div>
        </div>
        <template #footer>
            <el-button @click="cancel">
                取消
            </el-button>
            <el-button type="primary" @click="incoming">
                进件
            </el-button>
        </template>
    </el-drawer>
</template>

<script setup>
import { ref, defineExpose, defineEmits, getCurrentInstance } from 'vue'
import payment_method from '../../components/payment_method.vue';
import { ElMessage, ElMessageBox } from 'element-plus';
const emit = defineEmits(["success"])
const { proxy } = getCurrentInstance()
const isShow = ref(false)
const merchant = ref(null)
const channel = ref(null)
const formRef = ref(null)
const loading = ref(false)
const isFlowId = ref(false)
// const isDisabledApplyAgreement = ref(false)
// const applyAgreementLoading = ref(false)
// const agreementOrderNo = ref(null)
let incomingForm = ref({})
// const mechanism = ref([{
//     name: '953648', value: '953648'
// },
// {
//     name: '956116', value: '956116'
// }
// ])
const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `
const paymentRef = ref(null)
const incoming = () => {
    loading.value = true
    incomingSubmit()
}

const incomingSubmit = () => {
    const methods = paymentRef.value.sendMethodForm()
    if (channel.value.code == 'yinsheng') { // 银盛通道
        let params = {
            channel_id: channel.value.id,
            merchant_id: merchant.value.id,
            methods: methods
        }
        switch (incomeType.value) {
            case 1:
                if (isFlowId.value) {
                    params['sys_flow_id'] = incomingForm.value.sys_flow_id
                    proxy.$api.merchant.income.confirmMaterial(params).then(r => {
                        if (r.status == 200) {
                            window.open(r.data.signUrl)
                            // ElMessage({ message: '进件成功! 签约地址："' + r.data.signUrl + '" 如果是浏览器打开地址失败，进件需要手动复制签约地址到手机端进行合同签约！', type: 'success', duration: 0, showClose: true })
                            ElMessageBox.confirm(
                                '进件成功! 签约地址："' + r.data.signUrl + '"， 如果是浏览器打开地址失败，需要手动复制签约地址到手机端进行合同签约！',
                                '提示',
                                {
                                    confirmButtonText: '复制URL地址',
                                    type: "warning"
                                }
                            ).then(() => {
                                copyWord(r.data.signUrl)
                                isShow.value = false
                            })
                        } else {
                            ElMessage({ message: r.error.message, type: 'error' })
                        }

                        loading.value = false
                    })
                } else {
                    proxy.$api.merchant.income.incoming(params).then(r => {
                        if (r.status == 200) {
                            incomingForm.value.sys_flow_id = r.data.sys_flow_id
                            isFlowId.value = true
                        } else {
                            ElMessage({ message: r.error.message, type: 'error' })
                        }
                        loading.value = false
                    })
                }
                break;
            case 2:
                proxy.$api.merchant.income.againIncoming(params).then(r => {
                    incomeResponse(r, '重新进件成功')
                })
                break;
            case 3:
                proxy.$api.merchant.income.changeMerchantRate(params).then(r => {
                    incomeResponse(r, '费率修改成功')
                })
                break;
        }

    } else { // 其他通道
        const params = {
            channel_id: channel.value.id,
            merchant_id: merchant.value.id,
            methods: methods,
            // proto_no: incomingForm.value.proto_no ? incomingForm.value.proto_no : '',
            // institution_no: incomingForm.value.institution_no ? incomingForm.value.institution_no : ''
        }
        if (incomingForm.value.wx_mer_no) {
            params['wx_mer_no'] = incomingForm.value.wx_mer_no
        }
        switch (incomeType.value) {
            case 1:
                proxy.$api.merchant.income.incoming(params).then(r => {
                    incomeResponse(r, '进件申请成功')
                })
                break;
            case 2:
                proxy.$api.merchant.income.againIncoming(params).then(r => {
                    incomeResponse(r, '重新进件成功')
                })
                break;
            case 3:
                proxy.$api.merchant.income.changeMerchantRate(params).then(r => {
                    incomeResponse(r, '费率修改成功')
                })
                break;
        }

        // if (incomingForm.value.wx_mer_no) {
        //     params['wx_mer_no'] = incomingForm.value.wx_mer_no
        // }
        // proxy.$api.merchant.income.incoming(params).then(r => {
        //     if (r.status == 200) {
        //         ElMessage({ message: '进件申请成功', type: 'success' })

        //         if (channel.value.code != 'post') {
        //             emit("success", channel.value)
        //         }

        //         isShow.value = false
        //     } else {
        //         loading.value = false
        //         ElMessage({ message: r.error.message, type: 'error' })
        //     }
        // })
    }
}


const incomeResponse = (response, successMsg) => {
    if (response.status == 200) {
        ElMessage({ message: successMsg, type: 'success' })
        emit("success", channel.value)
        isShow.value = false
    } else {
        loading.value = false
        ElMessage({ message: response.error.message, type: 'error' })
    }
}

const cancel = () => {
    isShow.value = false
}
const incomeType = ref(null)
const open = (merchantInfo, channelInfo, type) => {
    isShow.value = true
    channel.value = channelInfo
    merchant.value = merchantInfo
    incomeType.value = type
}

const copyWord = (word) => {
    let tag = document.createElement('textarea');
    tag.setAttribute('id', 'cp_hgz_input');
    tag.value = word;
    document.getElementsByTagName('body')[0].appendChild(tag);
    document.getElementById('cp_hgz_input').select();
    document.execCommand('copy');
    document.getElementById('cp_hgz_input').remove();

    ElMessage({ message: '复制成功', type: 'success' })
}

defineExpose({ open })
</script>

<style lang="less">
.el-drawer__header {
    margin: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(200, 200, 200, .5);
}

.el-drawer__footer {
    margin: 0;
    padding-top: 15px;
    border-top: 1px solid rgba(200, 200, 200, .5);
}
</style>