<template>
    <el-dialog v-model="isShowList" width="80%" :draggable="true" :destroy-on-close="true" v-loading="true">
        <template #header>
            商户通道 - 商户名称 : {{ merchantInfo.merchant_name }}
        </template>
        <div id="table" v-loading="load">
            <c-table ref="table" :tableDataUrl="tableDataUrl" :tableDataOrigin="tableDataOrigin" :isShowPagination="false"
                @rowClassName="rowClassName">
                <el-table-column type="index" width="55" label="序号" :align="'center'" />
                <el-table-column prop="name" label="通道名称" />
                <!-- <el-table-column prop="code" label="通道编码" />
                <el-table-column prop="fee_rate" label="结算费率" />
                <el-table-column prop="settlement_type" label="结算周期" /> -->
                <el-table-column label="从商户账款扣款">
                    <template #default="scope">
                        <el-tag type="success" v-if="scope.row.deduct_type">是</el-tag>
                        <el-tag type="danger" v-else>否</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="状态">
                    <template #default="scope">
                        <el-tag type="danger" v-if="scope.row.status == null">未开通</el-tag>
                        <el-tag type="danger" v-if="(scope.row.status == 5)">开通中</el-tag>
                        <el-tag type="success" v-if="scope.row.status == 10" @click="editConfig(scope.row)"
                            style="cursor:pointer">已开通</el-tag>
                        <!-- <el-tag type="danger" v-if="(scope.row.status == 15)">开通失败</el-tag> -->
                        <el-tag type="danger" v-if="scope.row.status == 20">已提交</el-tag>
                        <el-tag type="danger" v-if="scope.row.status == 30">提交成功</el-tag>
                        <!-- <el-tag type="danger" v-if="scope.row.status == 40">提交失败</el-tag> -->
                        <el-tag type="danger" v-if="scope.row.status == 50">人工审核</el-tag>
                        <el-tag type="danger" v-if="scope.row.status == 60">审核中</el-tag>
                        <el-tag type="danger" v-if="scope.row.status == 70">审核通过</el-tag>
                        <el-tag type="danger"
                            v-if="scope.row.status == 80 || scope.row.status == 15 || scope.row.status == 40"
                            style="cursor:pointer">
                            <el-tooltip placement="top">
                                <template #content>
                                    {{ scope.row.response }}
                                </template>
                                审核驳回
                            </el-tooltip>
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="是否开启">
                    <template #default="scope">
                        <!-- <el-tag type="success" v-if="scope.row.auto_open">已开启</el-tag>
                        <el-tag v-else> 未开启</el-tag> -->
                        <el-switch v-model="scope.row.auto_open" :active-value="1" :inactive-value="0" active-text="是"
                            inactive-text="否" inline-prompt @change="changeOpenStatus(scope.row.auto_open, scope.row)" />
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-popconfirm v-if="scope.row.status == 10" :title="'确定要清除 ' + scope.row.name + ' 配置吗?'"
                            @confirm=deletePaymentChannels(scope.row)>
                            <template #reference>
                                <el-link type="primary">清除配置</el-link>
                            </template>
                        </el-popconfirm>
                        <el-link type="primary" v-if="!scope.row.status" @click="openConfig(scope.row)">开通配置</el-link>
                        <el-link type="primary"
                            v-if="scope.row.status == 80 || scope.row.status == 15 || scope.row.status == 40"
                            @click="merchantInComing(scope.row, 2)">重新提交</el-link>
                        <el-link type="primary" v-if="!scope.row.status" @click="merchantInComing(scope.row, 1)"
                            style="margin-left:15px">进件
                        </el-link>
                        <el-link type="primary" v-if="(scope.row.status == 10)" style="margin-left:15px"
                            @click="wxAuth(scope.row)">授权</el-link>
                        <el-link>
                            <el-dropdown v-if="(scope.row.status == 10)" style="margin-left:15px" :hideOnClick="false"
                                trigger="click">
                                <el-link type="primary">
                                    修改信息
                                </el-link>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item>
                                            <el-popconfirm confirm-button-text="Yes" cancel-button-text="No"
                                                title="确定要更新商户在渠道的信息吗？" @confirm="updateChannelMerInfo(scope.row)">
                                                <template #reference>
                                                    <el-link type="primary">商户信息</el-link>
                                                </template>
                                            </el-popconfirm>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <el-popconfirm v-if="(scope.row.status == 10)" confirm-button-text="Yes"
                                                cancel-button-text="No" title="确定要更新商户在渠道的结算资料吗？"
                                                @confirm="updateChannelSettlement(scope.row)">
                                                <template #reference>
                                                    <el-link type="primary">结算信息</el-link>
                                                </template>
                                            </el-popconfirm>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <el-popconfirm v-if="(scope.row.status == 10)" confirm-button-text="Yes"
                                                cancel-button-text="No" title="确定要更新商户在渠道的费率吗？"
                                                @confirm="merchantInComing(scope.row, 3)">
                                                <template #reference>
                                                    <el-link type="primary">费率信息</el-link>
                                                </template>
                                            </el-popconfirm>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </el-link>

                    </template>
                </el-table-column>
            </c-table>
        </div>

        <el-dialog v-model="isShowConfig" width="70%" :destroy-on-close="true" :draggable="true" @close="closeForm">
            <template #header>
                通道配置 - {{ channelInfo.name }}
            </template>
            <el-form ref="configForm" label-width="180px" :model="formData" :rules="rules">
                <el-row>
                    <el-col :span="16" :offset="2">
                        <el-form-item label="商户号:" prop="merchant_id">
                            <el-input v-model="formData.merchant_id" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- 平安（税票通） -->
                <template v-if="channelInfo.code == 'shuipiaotong'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="企业注册码:" prop="enterprise_reg">
                                <el-input v-model="formData.enterprise_reg" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="邀请码:" prop="invite_code">
                                <el-input v-model="formData.invite_code" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <!-- 电信（好码齐） -->
                <template v-if="channelInfo.code == 'haomaqi'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="APPID:" prop="app_id">
                                <el-input v-model="formData.app_id" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="APPSECRECT:" prop="app_secret">
                                <el-input v-model="formData.app_secret" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="OPENID:" prop="open_id">
                                <el-input v-model="formData.open_id" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="设备型号:" prop="model_num">
                                <el-input v-model="formData.model_num" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="设备号:" prop="device_num">
                                <el-input v-model="formData.device_num" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="设备类型:" prop="device_type">
                                <el-input v-model="formData.device_type" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="生产厂商:" prop="manufacturer">
                                <el-input v-model="formData.manufacturer" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <!-- 乐刷付 -->
                <template v-if="channelInfo.code == 'fbpay'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="orderSn:" prop="order_sn">
                                <el-input v-model="formData.order_sn" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="appId:" prop="app_id">
                                <el-input v-model="formData.app_id" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="secrect:" prop="secrect">
                                <el-input v-model="formData.secrect" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <!-- 建设银行 -->
                <template v-if="channelInfo.code == 'ccbpay'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="柜台号:" prop="posid">
                                <el-input v-model="formData.posid" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="分行号:" prop="branchid">
                                <el-input v-model="formData.branchid" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="商户公钥:" prop="publicKey">
                                <el-input v-model="formData.publicKey" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <!-- 拉卡拉 -->
                <template v-if="channelInfo.code == 'lakala'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="termNo:" prop="termNo">
                                <el-input v-model="formData.termNo" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <!-- 东莞银行 -->
                <template v-if="channelInfo.code == 'bankofdongguan'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="终端号:" prop="termNo">
                                <el-input v-model="formData.termNo" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
              <!-- 随行付 -->
              <template v-if="channelInfo.code == 'vbill'">
                <el-row>
                  <el-col :span="16" :offset="2">
                    <el-form-item label="微信子商户号subMchId:" prop="sub_mchid">
                      <el-input v-model="formData.sub_mchid" />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="16" :offset="2">
                    <el-form-item label="微信子商户appid:" prop="sub_appid">
                      <el-input v-model="formData.sub_appid" />
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
                <!-- 联拓富 -->
                <template v-if="channelInfo.code == 'ltf'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="APPID:" prop="app_id">
                                <el-input v-model="formData.app_id" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="APPSECRECT:" prop="app_secret">
                                <el-input v-model="formData.app_secret" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <!-- 会米付 -->
                <template v-if="channelInfo.code == 'huilaimi'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="系统商编号:" prop="systemMerchantNo">
                                <el-input v-model="formData.systemMerchantNo" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="设备号:" prop="device_num">
                                <el-input v-model="formData.device_num" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="公钥:" prop="publicKey">
                                <el-input v-model="formData.publicKey" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="私钥:" prop="privateKey">
                                <el-input v-model="formData.privateKey" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <!-- 银盛 -->
                <template v-if="channelInfo.code == 'yinsheng'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="商户名称:" prop="merchant_name">
                                <el-input v-model="formData.merchant_name" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <!-- 云卓全景 -->
                <template v-if="channelInfo.code == 'yunzhuoquanjing'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="设备号SN:" prop="sn">
                                <el-input v-model="formData.sn" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="开发者账号[devId]:" prop="dev_id">
                                <el-input v-model="formData.dev_id" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="加密密钥[aesKey]:" prop="key_str">
                                <el-input v-model="formData.key_str" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="接入商私钥[privateKey]:" prop="private_key">
                                <el-input type="textarea" v-model="formData.private_key" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="平台公钥[publicKey]:" prop="yzqj_public_key">
                                <el-input type="textarea" v-model="formData.yzqj_public_key" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <!-- 联动 -->
                <template v-if="channelInfo.code == 'ld'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="门店ID:">
                                <el-input v-model="formData.store_id" />
                            </el-form-item>
                        </el-col>
                    </el-row><el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="支付宝商户号:">
                                <el-input v-model="formData.alipay_id" />
                            </el-form-item>
                        </el-col>
                    </el-row><el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="微信商户号:">
                                <el-input v-model="formData.sub_mch_id" />
                            </el-form-item>
                        </el-col>
                    </el-row><el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="云闪付商户号:">
                                <el-input v-model="formData.union_id" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <!-- yapi -->
                <template v-if="channelInfo.code == 'yapi'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="密码:" prop="password">
                                <el-input v-model="formData.password" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="设备号:" prop="device_num">
                                <el-input v-model="formData.device_num" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <template v-if="channelInfo.code == 'icbc'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="appId:" prop="app_id">
                                <el-input v-model="formData.app_id" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="public_key:" prop="public_key">
                                <el-input type="textarea" v-model="formData.public_key" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="private_key:" prop="private_key">
                                <el-input type="textarea" v-model="formData.private_key" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <template v-if="channelInfo.code == 'ldhs'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="代理商ID:">
                                <el-input v-model="formData.agent_id" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <template v-if="channelInfo.code == 'xybank'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="商户名称" prop="merchant_name">
                                <el-input v-model="formData.merchant_name" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="机构号" prop="org_id">
                                <el-input v-model="formData.org_id" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="APP终端编号" prop="term_no">
                                <el-input v-model="formData.term_no" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="应用ID" prop="app_id">
                                <el-input v-model="formData.app_id" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="字段加密密钥" prop="encrypt">
                                <el-input v-model="formData.encrypt" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="签名私钥" prop="privateKey">
                                <el-input v-model="formData.privateKey" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="签名公钥" prop="publicKey">
                                <el-input v-model="formData.publicKey" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <template v-if="channelInfo.code == 'postpay'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="签名私钥" prop="privateKey">
                                <el-input v-model="formData.privateKey" placeholder="走应用授权机制的商户统一填0" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="商户应用授权操作:" required>
                                <el-button type="primary" :icon="Connection" @click="requestAuthPostPay">发起请求</el-button>
                                <el-button type="plain" :icon="Search" @click="queryAuthPostPay">查询状态</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <template v-if="channelInfo.code == 'pufapay'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="终端号" prop="term_no">
                                <el-input v-model="formData.term_no" />
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="签名私钥" prop="privateKey">
                                <el-input v-model="formData.privateKey" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="签名公钥" prop="publicKey">
                                <el-input v-model="formData.publicKey" />
                            </el-form-item>
                        </el-col>
                    </el-row>

                </template>

                <template v-if="channelInfo.code == 'hwc'">
                    <el-row>
                        <el-col :span="16" :offset="2">
                            <el-form-item label="商户Key:" prop="key">
                                <el-input v-model="formData.key" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <payment-method ref="methods" :methods="channelInfo.payment_methods" :isShowExtraCost="false"
                    :channelCode="channelInfo.code">
                </payment-method>
            </el-form>
            <template #footer>
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </template>
        </el-dialog>

        <incoming ref="incomingRef" @success='success' />

        <el-dialog v-model="isShowQrcode" width="800px" center>
            <div class="auth-box">
                <div>
                    <div class="title">微信商户号</div>
                    <div class="mer-no">{{ wxMerNo }}</div>
                    <div class="img"><img class="codeImg wximg" :src="wxQrCodeUrl" alt="微信授权图片" /></div>
                </div>
                <div>
                    <div class="title">支付宝商户号</div>
                    <div class="mer-no">{{ alipayMerNo }}</div>
                    <div class="img"><img class="codeImg" :src="alipayQrCodeUrl" alt="支付宝授权图片" /></div>
                </div>
            </div>
        </el-dialog>
    </el-dialog>
</template>

<script setup>
import { getCurrentInstance, ref, defineExpose, nextTick } from 'vue'
import cTable from '@/components/CustomTable'
import paymentMethod from '../../components/payment_method'
import incoming from './incoming'
import { ElMessage } from 'element-plus'
import { Connection, Search } from "@element-plus/icons-vue";

const { proxy } = getCurrentInstance() // 当前实例

const table = ref(null)
const tableDataUrl = ref(null)
const isShowList = ref(false)
const isShowConfig = ref(false)
const merchantInfo = ref(null)
const channelInfo = ref(null)
const tableDataOrigin = ref(null)
const methods = ref(null)
const tempTable = ref([])
const isConfigEdit = ref(false)

const isShowQrcode = ref(false)
let formData = ref({})

// let formData = ref({
//     id: '',
//     merchant_id: '',
//     enterprise_reg: '',
//     invite_code: '',
//     app_id: "",
//     app_secret: "",
//     device_num: "1",
//     device_type: "U0",
//     secrect: "",
//     branchid: '',
//     posid: '',
//     manufacturer: 'U09',
//     model_num: 'juhefu',
//     open_id: '',
//     order_sn: '',
//     publicKey: '',
//     privateKey: '',
//     termNo: '',
//     systemMerchantNo: '',
//     wx_applet_app_id: ''
// })

const message = '此项为必填'

const rules = ref({
    privateKey: [
        { required: true, message: message },
    ],
    systemMerchantNo: [
        { required: true, message: message },
    ],
    merchant_id: [
        { required: true, message: message },
    ],
    enterprise_reg: [
        { required: true, message: message },
    ],
    invite_code: [
        { required: true, message: message },
    ],
    app_id: [
        { required: true, message: message },
    ],
    app_secret: [
        { required: true, message: message },
    ],
    open_id: [
        { required: true, message: message },
    ],
    model_num: [
        { required: true, message: message },
    ],
    device_num: [
        { required: true, message: message },
    ],
    device_type: [
        { required: true, message: message },
    ],
    manufacturer: [
        { required: true, message: message },
    ],
    order_sn: [
        { required: true, message: message },
    ],
    secrect: [
        { required: true, message: message },
    ],
    posid: [
        { required: true, message: message },
    ],
    branchid: [
        { required: true, message: message },
    ],
    publicKey: [
        { required: true, message: message },
    ],
    termNo: [
        { required: true, message: message },
    ],
    password: [
        { required: true, message: message },
    ],
    org_id: [
        { required: true, message: message },
    ],
    term_no: [
        { required: true, message: message },
    ],
    encrypt: [
        { required: true, message: message },
    ],
    merchant_name: [
        { required: true, message: message },
    ],
    key: [
        { required: true, message: message },
    ],
    sn: [
        { required: true, message: message },
    ],
    dev_id: [
        { required: true, message: message },
    ],
    key_str: [
        { required: true, message: message },
    ],
    private_key: [
        { required: true, message: message },
    ],
    yzqj_public_key: [
        { required: true, message: message },
    ],
})

const rowClassName = (row) => {
    tempTable.value[row.row.code] = row.rowIndex
}

const closeForm = () => {
    formData.value = {}
    proxy.$refs.configForm.resetFields()
    isConfigEdit.value = false

}

const openConfig = (channel) => {
    isShowConfig.value = true
    channelInfo.value = channel
    formData.value.payment_channel_id = channel.id
    formData.value.id = merchantInfo.value.id
}

const editConfig = (channel) => {
    isConfigEdit.value = true

    formData.value.id = merchantInfo.value.id
    formData.value.payment_channel_id = channel.id

    const params = {
        payment_channel_id: channel.id,
        merchant_id: merchantInfo.value.id
    }

    proxy.$api.merchant.channel.payConfig(params).then(r => {
        if (r.status == 200) {
            const payment_methods = r.data[0].payment_methods
            const config = r.data[0].config
            let new_payment_methods = [0]
            payment_methods.forEach(element => {
                channel.payment_methods.forEach(elem => {
                    if (elem.id === element.payment_method_id) {
                        elem.pivot.cost = element.cost
                        new_payment_methods[elem.id] = elem
                    }
                })
            })

            for (let obj in config) {
                formData.value[obj] = config[obj]
            }

            channelInfo.value = channel
            isShowConfig.value = true
        }
    })
}

const submit = () => {
    proxy.$refs.configForm.validate((validate) => {
        if (validate) {
            let config = {}
            config['merchant_id'] = formData.value.merchant_id
            switch (channelInfo.value.code) {
                case 'shuipiaotong':
                    config['enterprise_reg'] = formData.value.enterprise_reg
                    config['invite_code'] = formData.value.invite_code
                    break;
                case 'haomaqi':
                    config['app_id'] = formData.value.app_id
                    config['app_secret'] = formData.value.app_secret
                    config['device_num'] = formData.value.device_num
                    config['device_type'] = formData.value.device_type
                    config['manufacturer'] = formData.value.manufacturer
                    config['model_num'] = formData.value.model_num
                    config['open_id'] = formData.value.open_id
                    break;
                case 'fbpay':
                    config['app_id'] = formData.value.app_id
                    config['order_sn'] = formData.value.order_sn
                    config['secrect'] = formData.value.secrect
                    break;
                case 'ccbpay':
                    config['branchid'] = formData.value.branchid
                    config['posid'] = formData.value.posid
                    config['publicKey'] = formData.value.publicKey
                    break;
                case 'lakala':
                    config['termNo'] = formData.value.termNo
                    break;
                case 'bankofdongguan':
                    config['termNo'] = formData.value.termNo
                    break;
                case 'vbill':
                    config['mno'] = formData.value.merchant_id
                    config['sub_mchid'] = formData.value.sub_mchid
                    config['sub_appid'] = formData.value.sub_appid
                    break;
                case 'ltf':
                    config['app_id'] = formData.value.app_id
                    config['app_secret'] = formData.value.app_secret
                    break;
                case 'huilaimi':
                    config['systemMerchantNo'] = formData.value.systemMerchantNo
                    config['device_num'] = formData.value.device_num
                    config['publicKey'] = formData.value.publicKey
                    config['privateKey'] = formData.value.privateKey
                    break;
                case 'yinsheng':
                    config['merchant_name'] = formData.value.merchant_name
                    break;
                case 'yunzhuoquanjing':
                    config['sn'] = formData.value.sn
                    config['dev_id'] = formData.value.dev_id
                    config['key_str'] = formData.value.key_str
                    config['private_key'] = formData.value.private_key
                    config['yzqj_public_key'] = formData.value.yzqj_public_key
                    break;
                case 'ld':
                    config['store_id'] = formData.value.store_id
                    config['alipay_id'] = formData.value.alipay_id
                    config['sub_mch_id'] = formData.value.sub_mch_id
                    config['union_id'] = formData.value.union_id
                    break;
                case 'yapi':
                    config['password'] = formData.value.password
                    config['device_num'] = formData.value.device_num
                    break;
                case 'icbc':
                    config['app_id'] = formData.value.app_id
                    config['private_key'] = formData.value.private_key
                    config['public_key'] = formData.value.public_key
                    break;
                case 'ldhs':
                    config['agent_id'] = formData.value.agent_id
                    break;
                case 'xybank':
                    config['org_id'] = formData.value.org_id
                    config['term_no'] = formData.value.term_no
                    config['encrypt'] = formData.value.encrypt
                    config['privateKey'] = formData.value.privateKey
                    config['publicKey'] = formData.value.publicKey
                    config['app_id'] = formData.value.app_id
                    config['merchant_name'] = formData.value.merchant_name
                    break;
                case 'postpay':
                    config['privateKey'] = formData.value.privateKey
                    break;
                case 'pufapay':
                    config['term_no'] = formData.value.term_no
                    break;
                case 'hwc':
                    config['key'] = formData.value.key
                    break;
            }

            formData.value.config = config
            const method = proxy.$refs.methods.sendMethodForm()
            if (!method) {
                ElMessage({ message: '费率不合法' })
                return false
            }

            formData.value.payment_methods = method
            if (isConfigEdit.value) {
                proxy.$api.merchant.channel.editPaymentChannels(formData.value.id, formData.value).then((r) => {
                    setRes(r)
                })
            } else {
                proxy.$api.merchant.channel.savePaymentChannels(formData.value.id, formData.value).then((r) => {
                    setRes(r)
                })
            }
        }
    })
}

const setRes = (res) => {
    if (res.status == 200) {
        formData.value.config = {}
        isShowConfig.value = false
        isConfigEdit.value = false
        changeChannelStatus(10)
    }
}

const cancel = () => {
    isShowConfig.value = false
}

const deletePaymentChannels = (channel) => {
    channelInfo.value = channel
    const params = { id: merchantInfo.value.id, payment_channel_id: channel.id }
    proxy.$api.merchant.channel.deletePaymentChannels(merchantInfo.value.id, params).then((r) => {
        if (r.status == 200) {
            changeChannelStatus(null)
        }
    })
}

const changeChannelStatus = (status) => {
    const rowIndex = tempTable.value[channelInfo.value.code]
    tableDataOrigin.value[rowIndex].status = status
}

const changeOpenStatus = (val, row) => {
    if (!row.status || row.status != 10) {
        return
    }

    const params = {
        auto_open: val,
        merchant_id: merchantInfo.value.id,
        payment_channel_id: row.id
    }
    proxy.$api.merchant.channel.changePaymentChannelAutoOpen(params).then(r => {
        if (r.status == 200) {
            ElMessage({ message: '修改成功', type: 'success' })
        } else {
            ElMessage({ message: r.error.message, type: 'error', duration: 20000, showClose: true, center: true })
        }
    })
}

const incomingRef = ref(null)
/**
 * channelInfo 通道
 * type 进件类型 1 进件，2 重新提交，3 修改商户费率
 */
const merchantInComing = (channelInfo, type) => {
    channelInfo.value = channelInfo
    nextTick(() => {
        incomingRef.value.open(merchantInfo.value, channelInfo, type)
    })
}

const load = ref(false)
/**修改商户资料 */
const updateChannelMerInfo = (channelInfo) => {
    load.value = true
    const params = {
        merchant_id: merchantInfo.value.id,
        channel_id: channelInfo.id
    }
    console.log(channelInfo)
    proxy.$api.merchant.income.changeMerchantInfo(params).then(r => {
        if (r.status == 200) {
            ElMessage({ message: '资料提交成功', type: 'success' });
            success(channelInfo)
        } else {
            ElMessage({ message: r.error.message, type: 'error' })
        }

        load.value = false
    })
}

/**修改结算卡 */
const updateChannelSettlement = (channelInfo) => {
    load.value = true
    const params = {
        merchant_id: merchantInfo.value.id,
        channel_id: channelInfo.id
    }

    proxy.$api.merchant.income.changeMerchantSettlement(params).then(r => {
        if (r.status == 200) {
            ElMessage({ message: '结算卡信息提交成功', type: 'success' });
            success(channelInfo)
        } else {
            ElMessage({ message: r.error.message, type: 'error' })
        }

        load.value = false
    })
}

const wxQrCodeUrl = ref('')
const alipayQrCodeUrl = ref('')
const wxMerNo = ref('')
const alipayMerNo = ref('')
const wxAuth = (channelInfo) => {
    proxy.$api.merchant.auth({
        merchant_id: merchantInfo.value.id,
        channel_id: channelInfo.id
    }).then(r => {

        if (r.status == 200) {
            let wxIsSuccess = true
            let alipayIsSuccess = true
            r.data.forEach(item => {
                if (item.auth_type == 'wx') {
                    if (item.status == 'ok') {
                        wxQrCodeUrl.value = 'data:image/png;base64,' + item.qrCodeData
                        wxMerNo.value = item.subMchId
                    } else {
                        ElMessage({ message: '微信：' + item.message, type: 'error' })
                        wxIsSuccess = false
                    }
                }

                if (item.auth_type == 'alipay') {
                    if (item.status == 'ok') {
                        alipayQrCodeUrl.value = item.qrCodeData
                        alipayMerNo.value = item.subMchId
                    } else {
                        ElMessage({ message: '支付宝：' + item.message, type: 'error' })
                        alipayIsSuccess = false
                    }
                }
            })
            if (wxIsSuccess || alipayIsSuccess) {
                isShowQrcode.value = true
            }

        } else {
            ElMessage({ message: r.data.message, type: 'error' })
        }

    })
}

const success = (channel) => {
    channelInfo.value = channel
    changeChannelStatus(20)
}

const open = (item) => {
    isShowList.value = true
    merchantInfo.value = item

    proxy.$api.merchant.channel.channel(item.id).then((r) => {
        tableDataOrigin.value = r.data
        table.value.setTableData(r.data)
    })
}

const requestAuthPostPay = () => {
    proxy.$api.merchant.channel.postpayRequestAuth({
        shop_id: formData.value.merchant_id
    }).then((r) => {
        if (r.status == 200) {
            ElMessage({
                message: '操作成功',
                type: 'success',
            })
        }
    })
}
const queryAuthPostPay = () => {
    proxy.$api.merchant.channel.postpayQueryAuth({
        shop_id: formData.value.merchant_id
    }).then((r) => {
        if (r.status == 200) {
            console.log('postpay res=', r)
            ElMessage({
                message: r.data.data.status == 'success' ? '已成功授权' : '等待商户确认授权中...',
                type: 'success',
            })
        }
    })
}

defineExpose({ open })
</script>

<style lang="less" scoped>
.el-card__header {
    background-color: #fafafa !important
}

.per_cent {
    text-align: center;
    line-height: 32px;
}

.auth-box {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    >div {
        .title {
            text-align: center;
            margin-bottom: 15px;
            font-size: 18px;
        }

        .mer-no {
            text-align: center;
            margin-bottom: 25px;
        }

        .codeImg {
            width: 300px;
            border: 1px solid rgb(220, 220, 220);
        }
    }

}
</style>
